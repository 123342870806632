import { EmojiConvertor } from "emoji-js";
import GraphemeSplitter from "grapheme-splitter";
import * as slack from "@pollyslack/slack";
import {
  Cadence,
  DurationUnit,
  ResultsDeliveryType,
} from "@pollyslack/types/src/common";
import JSXSlack from "jsx-slack";
import * as _ from "lodash";
import moment from "moment-timezone";
import { DEFAULT_TIME_ZONE, MONTHS_UNBLOCK_RESULTS_FOR } from "./constants";
import {
  CHANNEL_ID_WITH_BLOCK_LEVEL_LOGGING,
  ORG_ID_WITH_BLOCK_LEVEL_LOGGING,
  SLACK_TEAM_ID_WITH_BLOCK_LEVEL_LOGGING,
} from "./models/constants";
import { getPostOptionsFromQuestionTemplate } from "./models/services/questionService/QuestionOptionsService";
import { questionTemplateMap } from "./models/services/questionService/questionTemplates";
import { QuestionType } from "./schemas/questions/questionTypes";
import { WorkflowDynamicAudience, WorkflowEventType } from "./schemas/workflow";
import { sanitizeJSXString } from "./slack/ux/common/utils";
import {
  Frequency,
  PollType,
  PollyDuration,
  PostOptions,
  PostOptionsTemplate,
  SlackRole,
  SlackRolesInfo,
  Team,
  User,
} from "./types";
import { GuestUserId, PollyId, PollyUserId } from "@pollyai/types";

// `is` is a no-op type assertion
// Useful in switch statements to get exhausitivity checking.
//    ex: default: is<never>(varBeingSwitchedOn);
export function is<T>(t: T): T {
  return t;
}

// User-defined guard to do type-inference based on a property existing or not.
// Use it in an if and then you shouldn't need to typecast inside the then clause.
// From https://stackoverflow.com/a/45492351/1675252
export function hasKey<K extends string>(
  val: any,
  key: K,
): val is { [P in K]: any } {
  return key in val;
}

// Get the values of an enum.
// Compare to Object.keys(), which will gives the enum's keys.
// We generally prefer string enums where the keys and values are equal, so in practice they should be the same.
export function getEnumValues<T>(enumObj: T): T[keyof T][] {
  const values: T[keyof T][] = [];
  for (const key in enumObj) {
    values.push(enumObj[key]);
  }
  return values;
}

export function buildMapBy<T, K extends keyof T>(
  array: T[],
  prop: K,
): Map<T[K], T> {
  const map = new Map<T[K], T>();
  for (const obj of array) {
    map.set(obj[prop], obj);
  }
  return map;
}

// JSXSlack wrapper used to sanitize JSON from jsx-slack modals
export function PollyJSXSlackModal(
  raw: JSXSlack.JSX.Element,
): slack.BlockKit.ModalView {
  const modal = JSXSlack(raw) as slack.BlockKit.ModalView;
  if (!["modal", "workflow_step"].includes(modal.type)) {
    throw new Error("PollyJSXSlackModal requires a <Modal>");
  }
  // sanitize the blocks, replacing breaking spaces with non-breaking spaces
  modal.blocks = JSON.parse(sanitizeJSXString(JSON.stringify(modal.blocks)));
  return modal;
}

// JSXSlack wrapper used to sanitized JSON from jsx-slack
export function PollyJSXSlack(blocks: JSXSlack.Node<any>): any {
  // convert JSX to JSON for slack
  const slackBlockJson = JSXSlack(blocks);
  // stringify and sanitize the
  const slackBlockString = JSON.stringify(slackBlockJson);
  const sanitizedBlockString = sanitizeJSXString(slackBlockString);
  // convert back to JSON for now
  // consider updating so we return string to simplify for callers
  return JSON.parse(sanitizedBlockString);
}

// Helper to tell apart mongo ids from slack ids
export function isMongoIdLength(id: string): boolean {
  const len = id.length;
  // Meteor's random id function, which we also use elsewhere, uses length 17
  // ids which mongo generates by itself have length 24
  return len === 17 || len === 24;
}

// Could use a library like qs to do this, but our use cases are simple enough for now.
export function appendToQs(qs: string, key: string, value: string): string {
  if (qs[qs.length - 1] !== "?") {
    qs += "&";
  }
  return qs + key + "=" + encodeURIComponent(value);
}

export function getFrequencyDisplayText(frequency: Frequency): string {
  switch (frequency) {
    case Cadence.DAILY_INCLUDING_WEEKENDS:
      return "Every day (recurring)";
    case Cadence.DAILY:
      return "Every weekday (recurring)";
    case Cadence.WEEKLY:
      return "Every week (recurring)";
    case Cadence.BIWEEKLY:
      return "Every 2 weeks (recurring)";
    case Cadence.TRIWEEKLY:
      return "Every 3 weeks (recurring)";
    case Cadence.MONTHLY:
      return "Every month (recurring)";
    case Cadence.THREE_MONTHS:
      return "Every 3 months (recurring)";
    case Cadence.BIANNUALLY:
      return "Every 6 months (recurring)";
    case "scheduled-one-time":
      return "Scheduled one-time";
    case "one-time":
      return "One-time";
    default:
      is<never>(frequency);
      throw Error(`Unknown frequency value ${frequency}`);
  }
}

export function getCadenceDisplayText(cadence: Cadence): string {
  switch (cadence) {
    case Cadence.DAILY_INCLUDING_WEEKENDS:
      return "Daily";
    case Cadence.DAILY:
      return "Daily (weekdays only)";
    case Cadence.WEEKLY:
      return "Weekly";
    case Cadence.BIWEEKLY:
      return "Biweekly";
    case Cadence.TRIWEEKLY:
      return "Triweekly";
    case Cadence.MONTHLY:
      return "Monthly";
    case Cadence.THREE_MONTHS:
      return "Quarterly";
    case Cadence.BIANNUALLY:
      return "Biannually";
    default:
      is<never>(cadence);
      throw Error(`Unknown cadence value ${cadence}`);
  }
}

export function getPollTypeDisplayText(pollType: PollType | string): string {
  // All question types will eventually have a question template. We can get rid of the long switch statement at that point
  const questionTemplate = questionTemplateMap.get(pollType as PollType);
  if (questionTemplate) {
    return questionTemplate.userFacingLabel;
  } else {
    switch (pollType) {
      case PollType.FIVE_POINT:
        return "Agree/Disagree";
      case PollType.SEVEN_POINT:
        throw Error("Attempting to display a SEVEN_POINT polly");
      case PollType.ONE_TO_FIVE:
        return "1-to-5";
      case PollType.ONE_TO_TEN:
        return "1-to-10";
      case PollType.NPS:
        return "NPS";
      case PollType.VERBAL_BINARY:
        return "Multiple choice";
      case PollType.OPEN_ENDED:
        return "Open ended";
      case PollType.RANKED:
        return "Rank order";
      case PollType.RANKED_WEIGHTED_VOTING:
        return ":ballot_box_with_ballot: Weighted Voting";
      case PollType.ALLOCATION:
        return "Point allocation";
      case PollType.IMAGE:
        return "Image polly";
      case PollType.RANK_FIVE_EMOJI:
        // Should never get here but needed for typescript
        return questionTemplateMap.get(pollType)?.userFacingLabel;
      case "wordCloud":
        return "Word Cloud";
      default:
        if (typeof pollType !== "string") {
          is<never>(pollType);
        }
        throw Error(`Unknown pollType value ${pollType}`);
    }
  }
}

export function getPollTypeUserEventText(pollType: PollType): string {
  switch (pollType) {
    case PollType.FIVE_POINT:
      return "AgreeDisagree";
    case PollType.SEVEN_POINT:
      throw Error("Attempting to display a SEVEN_POINT polly");
    case PollType.ONE_TO_FIVE:
      return "1to5";
    case PollType.ONE_TO_TEN:
      return "1to10";
    case PollType.NPS:
      return "NPS";
    case PollType.VERBAL_BINARY:
      return "MultipleChoice";
    case PollType.OPEN_ENDED:
      return "OpenEnded";
    case PollType.RANKED:
      return "RankOrder";
    case PollType.RANKED_WEIGHTED_VOTING:
      return "WeightedVoting";
    case PollType.ALLOCATION:
      return "PointAllocation";
    case PollType.IMAGE:
      return "Image polly";
    case PollType.RANK_FIVE_EMOJI:
      return "EmojiRank";
    default:
      is<never>(pollType);
      throw Error(`Unknown pollType value ${pollType}`);
  }
}

export function isNumericPollType(pollType: PollType): boolean {
  // Agree/disagree isn't literally numeric, but we do translate its results to numbers
  // for charts, so it's included here
  return (
    pollType === PollType.FIVE_POINT ||
    pollType === PollType.SEVEN_POINT ||
    pollType === PollType.ONE_TO_FIVE ||
    pollType === PollType.ONE_TO_TEN ||
    pollType === PollType.NPS
  );
}

export function isPollTypeWithCustomOptions(pollType: PollType): boolean {
  return (
    pollType === PollType.VERBAL_BINARY ||
    pollType === PollType.RANKED ||
    pollType === PollType.RANKED_WEIGHTED_VOTING ||
    pollType === PollType.ALLOCATION ||
    pollType === PollType.IMAGE
  );
}

export function getCadenceLengthInDays(cadence: Cadence): number {
  switch (cadence) {
    case Cadence.DAILY_INCLUDING_WEEKENDS: // intentional fall-through
    case Cadence.DAILY:
      return 1;
    case Cadence.WEEKLY:
      return 7;
    case Cadence.BIWEEKLY:
      return 14;
    case Cadence.TRIWEEKLY:
      return 21;
    case Cadence.MONTHLY:
      return 30;
    case Cadence.THREE_MONTHS:
      return 90;
    case Cadence.BIANNUALLY:
      return 180;
    default:
      is<never>(cadence);
      throw Error(`Unknown brief cadence ${cadence} of type ${typeof cadence}`);
  }
}

export function calcDueDate(
  cadence: Cadence,
  nextBriefDate: Date,
  options: {
    mqAllowWeekends?: boolean;
    originalNextBriefDate?: Date;
    timezone?: string;
  } = {},
): Date {
  // if we can't get tz information for the user don't try to be clever with DST
  let endDate = moment(nextBriefDate);
  if (options.timezone) {
    endDate = moment.tz(nextBriefDate, options.timezone);
  }
  switch (cadence) {
    case Cadence.WEEKLY:
      endDate.add(1, "weeks");
      break;
    case Cadence.BIWEEKLY:
      endDate.add(2, "weeks");
      break;
    case Cadence.TRIWEEKLY:
      endDate.add(3, "weeks");
      break;
    case Cadence.MONTHLY:
      endDate.add(1, "months");
      break;
    case Cadence.THREE_MONTHS:
      endDate.add(3, "months");
      break;
    case Cadence.BIANNUALLY:
      endDate.add(6, "months");
      break;
    case Cadence.DAILY_INCLUDING_WEEKENDS:
      endDate.add(1, "days");
      break;
    case Cadence.DAILY:
      endDate.add(1, "days");
      const d = endDate.day();
      if (d === 0) {
        // bump 1 day if sunday
        endDate.add(1, "days");
      } else if (d === 6) {
        // bump two days if saturday
        endDate.add(2, "days");
      }
      break;
    default:
      is<never>(cadence);
      throw Error(`Unknown brief cadence ${cadence} of type ${typeof cadence}`);
  }

  if (
    cadence === Cadence.MONTHLY ||
    cadence === Cadence.THREE_MONTHS ||
    cadence === Cadence.BIANNUALLY
  ) {
    if (options.originalNextBriefDate) {
      // In case the day of the month in nextBriefDate was different than in the original (e.g.
      // because the month it was on is shorter than the original's month), restore the original
      // day if possible.
      const originalMoment = options.timezone
        ? moment.tz(options.originalNextBriefDate, options.timezone)
        : moment(options.originalNextBriefDate);
      if (originalMoment.date() !== endDate.date()) {
        const candidateEndDate = endDate.clone();
        candidateEndDate.date(originalMoment.date());
        // If we're in the same month, that means the month has the day we want, so we're good
        if (candidateEndDate.month() === endDate.month()) {
          endDate = candidateEndDate;
        }
      }
    }

    if (!options.mqAllowWeekends) {
      const d = endDate.day();
      if (d === 0 || d === 6) {
        const adjustedEndDate = endDate.clone();
        const daysTilMonday = d === 0 ? 1 : 2;
        adjustedEndDate.add(daysTilMonday, "days");
        if (adjustedEndDate.month() !== endDate.month()) {
          // Don't want to bump it into the next month, so go back to the friday instead
          adjustedEndDate.subtract(3, "days");
        }
        endDate = adjustedEndDate;
      }
    }
  }

  return endDate.toDate();
}

export function getSlackRole(rolesInfo: SlackRolesInfo): SlackRole {
  if (rolesInfo.is_primary_owner) {
    return SlackRole.PRIMARY_OWNER;
  } else if (rolesInfo.enterprise_user && rolesInfo.enterprise_user.is_owner) {
    return SlackRole.EG_OWNER;
  } else if (rolesInfo.enterprise_user && rolesInfo.enterprise_user.is_admin) {
    return SlackRole.EG_ADMIN;
  } else if (rolesInfo.is_owner) {
    return SlackRole.OWNER;
  } else if (rolesInfo.is_admin) {
    return SlackRole.ADMIN;
  } else if (rolesInfo.is_ultra_restricted) {
    return SlackRole.SINGLE_CHANNEL_GUEST;
  } else if (rolesInfo.is_restricted) {
    return SlackRole.GUEST;
  } else {
    return SlackRole.MEMBER;
  }
}

export function getSlackRoleDisplayText(slackRole: SlackRole): string {
  switch (slackRole) {
    case SlackRole.SINGLE_CHANNEL_GUEST:
      return "Single-Channel Guest";
    case SlackRole.GUEST:
      return "Multi-Channel Guest";
    case SlackRole.MEMBER:
      return "Member";
    case SlackRole.ADMIN:
      return "Workspace Admin";
    case SlackRole.OWNER:
      return "Workspace Owner";
    case SlackRole.EG_ADMIN:
      return "Organization Admin";
    case SlackRole.EG_OWNER:
      return "Organization Owner";
    case SlackRole.PRIMARY_OWNER:
      return "Primary Owner";
    default:
      is<never>(slackRole);
      throw new Error(`Unknown SlackRole value ${slackRole}`);
  }
}

export function getWorkflowDynamicAudienceText(
  audience: WorkflowDynamicAudience,
): string {
  switch (audience) {
    case WorkflowDynamicAudience.user:
      return "User";
    case WorkflowDynamicAudience.user2:
      return "Second User";
    case WorkflowDynamicAudience.channel:
      return "Channel";
    case WorkflowDynamicAudience.messagePoster:
      return "Message Sender";
    case WorkflowDynamicAudience.fromApiParameters:
      return "Api Parameter"; // This should never happen, but just incase.
    default:
      is<never>(audience);
      throw new Error(`Unknown workflow dynamic audience value ${audience}`);
  }
}

export function getWorkflowEventTypeDisplayText(
  eventType: WorkflowEventType,
): string {
  switch (eventType) {
    case WorkflowEventType.userAddedToWorkspace:
      return "user joining Slack";
    case WorkflowEventType.userAddedToChannel:
      return "user joining a Slack channel";
    case WorkflowEventType.emojiReaction:
      return "user reacting with an emoji";
    case WorkflowEventType.slashCommandInvocation:
      return "slash command";
    case WorkflowEventType.apiInvocation:
      return "Polly API";
    case WorkflowEventType.secondEmojiReaction:
      return "two users reacting with emojis";
    default:
      is<never>(eventType);
      throw new Error(`Invalid workflow event type ${eventType}`);
  }
}

export function getDurationUnitDisplayText(unit: DurationUnit): string {
  switch (unit) {
    case DurationUnit.minutes:
      return "minute";
    case DurationUnit.hours:
      return "hour";
    case DurationUnit.days:
      return "day";
    case DurationUnit.weeks:
      return "week";
    case DurationUnit.months:
      return "months";
    case DurationUnit.years:
      return "year";
    default:
      is<never>(unit);
      throw new Error(`Invalid DurationUnit ${unit}`);
  }
}

export function getPollyDurationDisplayText(duration: PollyDuration): string {
  if (duration.value === 0) {
    return "immediately";
  } else {
    const plural = duration.value !== 1;
    let unitStr = getDurationUnitDisplayText(duration.unit);
    if (plural) {
      unitStr += "s";
    }
    return `after ${duration.value} ${unitStr}`;
  }
}

export function calcChartGridLines(
  cadence: Cadence,
  sentDate: Date,
  options: {
    timezone?: string;
  } = {},
): Date {
  // if we can't get tz information for the user don't try to be clever with DST
  let endDate = moment(sentDate);
  if (options.timezone) {
    endDate = moment.tz(sentDate, options.timezone);
  }

  switch (cadence) {
    case Cadence.WEEKLY:
      endDate.subtract(1, "weeks");
      break;
    case Cadence.BIANNUALLY:
    case Cadence.BIWEEKLY:
      endDate.subtract(2, "weeks");
      break;
    case Cadence.TRIWEEKLY:
      endDate.subtract(3, "weeks");
      break;
    case Cadence.MONTHLY:
      endDate.subtract(1, "months");
      break;
    case Cadence.THREE_MONTHS:
      endDate.subtract(3, "months");
      break;
    case Cadence.DAILY_INCLUDING_WEEKENDS:
      endDate.subtract(1, "days");
      break;
    case Cadence.DAILY:
      endDate.subtract(1, "days");
      break;
    default:
      is<never>(cadence);
      throw Error(`Unknown brief cadence ${cadence} of type ${typeof cadence}`);
  }

  return endDate.toDate();
}

/**
 * Returns the earliest time at which the schedule should occur after the 'after' parameter.
 * e.g. after = 12pm, a daily schedule ran yesterday at 3pm, will result in a date representing
 * today at 3pm.
 * @param after Finds a time matching the schedule after this timestamp
 * @param cadence Frequency with which the schedule occurs
 * @param lastScheduleTime The last time the schedule was intended to be delivered
 * @param mqAllowWeekends For monthly or quarterly cadence, set to true to allow weekends
 * @param originalScheduleTime The original schedule time. Helpful for preserving the day for
 * monthly or longer schedules
 * @param timezone String timezone identifier e.g. "America/New_York"
 */
export function getNextScheduleAfter(
  after: Date,
  cadence: Cadence,
  lastScheduleTime: Date,
  mqAllowWeekends: boolean = false,
  originalScheduleTime?: Date,
  timezone?: string,
): Date {
  let nextTime = calcDueDate(cadence, lastScheduleTime, {
    mqAllowWeekends,
    originalNextBriefDate: originalScheduleTime,
    timezone,
  });

  while (nextTime <= after) {
    nextTime = calcDueDate(cadence, nextTime, {
      mqAllowWeekends,
      originalNextBriefDate: originalScheduleTime,
      timezone,
    });
  }
  return nextTime;
}

export function mapNumberToEmoji(emojiNumber: number): string {
  const numberEnum = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "keycap_ten",
  ];
  return numberEnum[emojiNumber];
}

export function getVotingOptions(
  pollType: PollType,
  optionArray: PostOptionsTemplate[] | string[],
): PostOptions[] {
  const options = generatePostOptionsTemplates(optionArray);
  const questionTemplate = questionTemplateMap.get(pollType);
  let votingOptions: PostOptions[] = [];
  if (questionTemplate) {
    votingOptions = getPostOptionsFromQuestionTemplate(pollType);
  }
  switch (pollType) {
    case PollType.FIVE_POINT: {
      votingOptions = [
        { _id: 0, value: "Strongly Disagree", votes: 0, emoji: "frowning" },
        { _id: 1, value: "Disagree", votes: 0, emoji: "disappointed" },
        { _id: 2, value: "Neutral", votes: 0, emoji: "neutral_face" },
        { _id: 3, value: "Agree", votes: 0, emoji: "relieved" },
        { _id: 4, value: "Strongly Agree", votes: 0, emoji: "grinning" },
      ];
      break;
    }
    case PollType.SEVEN_POINT: {
      votingOptions = [
        { _id: 0, value: "Strongly Disagree", votes: 0, emoji: "frowning" },
        { _id: 1, value: "Disagree", votes: 0, emoji: "disappointed" },
        {
          _id: 2,
          value: "Somewhat Disagree",
          votes: 0,
          emoji: "slightly_frowning_face",
        },
        { _id: 3, value: "Neutral", votes: 0, emoji: "neutral_face" },
        {
          _id: 4,
          value: "Somewhat Agree",
          votes: 0,
          emoji: "slightly_smiling_face",
        },
        { _id: 5, value: "Agree", votes: 0, emoji: "relieved" },
        { _id: 6, value: "Strongly Agree", votes: 0, emoji: "grinning" },
      ];
      break;
    }
    case PollType.VERBAL_BINARY:
    case PollType.RANKED:
    case PollType.RANKED_WEIGHTED_VOTING:
    case PollType.ALLOCATION: {
      for (const option of options) {
        const newOption: PostOptions = {
          ...option,
          emoji: mapNumberToEmoji(option._id + 1),
          votes: 0,
        };
        votingOptions.push(newOption);
      }
      break;
    }
    case PollType.NPS: {
      for (let i = 0; i < 11; i++) {
        votingOptions.push({
          _id: i,
          value: i.toString(),
          votes: 0,
          emoji: mapNumberToEmoji(i),
        });
      }
      break;
    }
    case PollType.ONE_TO_TEN: {
      for (let i = 0; i < 10; i++) {
        votingOptions.push({
          _id: i,
          value: (i + 1).toString(),
          votes: 0,
          emoji: mapNumberToEmoji(i + 1),
        });
      }
      break;
    }
    case PollType.ONE_TO_FIVE: {
      for (let i = 0; i < 5; i++) {
        votingOptions.push({
          _id: i,
          value: (i + 1).toString(),
          votes: 0,
          emoji: mapNumberToEmoji(i + 1),
        });
      }
      break;
    }
    case PollType.OPEN_ENDED: {
      votingOptions = [];
      break;
    }
    case PollType.IMAGE: {
      for (const option of options) {
        const newOption: PostOptions = {
          ...option,
          emoji: mapNumberToEmoji(option._id + 1),
          votes: 0,
          image_url: option.image_url,
        };
        votingOptions.push(newOption);
      }
      break;
    }
    case PollType.RANK_FIVE_EMOJI: {
      // Should never get here. Placeholder needed for typescript
      break;
    }
    default:
      is<never>(pollType);
  }
  return votingOptions;
}

export function generatePostOptionsTemplates(
  options: string[] | PostOptionsTemplate[],
): PostOptionsTemplate[] {
  if (_.isNil(options) || options.length === 0) {
    return [];
  } else if (typeof options[0] === "string") {
    const strs = options as string[];
    return strs.map((s: string, i: number) => ({
      _id: i,
      orderIndex: i,
      value: s,
    }));
  } else {
    return options as PostOptionsTemplate[];
  }
}

export function projectToPostOptionsTemplates(
  options: PostOptions[] | PostOptionsTemplate[],
): PostOptionsTemplate[] {
  // PostOptions has all the properties of PostOptionsTemplate. Really, PostOptions[] is in the type signature just
  // to be more explicit.  We cast here so we can use `.map()`.
  const casted: PostOptionsTemplate[] = options;
  return casted.map((o) => ({
    _id: o._id,
    value: o.value,
    renderIndex: o.renderIndex,
    orderIndex: o.orderIndex,
    ...(_.isNil(o.image_url) ? {} : { image_url: o.image_url }),
  }));
}

/**
 * Calculates the average score for a variety of different question types.
 * The logic for using or hiding the score (based on the question type) should be controlled by
 * the caller since this will generically calculate a score for most question types.
 * @param pollType the question type of the poll (used to calculate average accordingly)
 * @param options the options (including votes for each option) for the poll
 */
export function calculateAverageScore(
  pollType: PollType,
  options: PostOptions[],
  questionType: QuestionType,
): string | undefined {
  let totalVotes = 0;
  let unaveragedSum = 0;

  if (questionType && questionType == QuestionType.ORDINAL) {
    // Current case only handles ORDINAL w.r.t to questionType other cases are handled w.r.t PollyType
    const orderedOptions = _.sortBy(options, "orderIndex");
    for (let i = 0; i < orderedOptions.length; i++) {
      const votes = orderedOptions[i].votes ?? 0;
      // Need to offset by 1 unless its NPS
      unaveragedSum += votes * (i + 1);
      totalVotes += votes;
    }
  } else {
    switch (pollType) {
      case PollType.FIVE_POINT:
      case PollType.ONE_TO_TEN:
      case PollType.RANK_FIVE_EMOJI:
      case PollType.ONE_TO_FIVE: {
        // Only order again if needed
        const orderedOptions = _.sortBy(options, "_id");
        for (let i = 0; i < orderedOptions.length; i++) {
          const votes = orderedOptions[i].votes ?? 0;
          // Need to offset by 1 unless its NPS
          unaveragedSum += votes * (i + 1);
          totalVotes += votes;
        }
        break;
      }
      case PollType.NPS: {
        // Only order again if needed
        const orderedOptions = _.sortBy(options, "_id");
        for (let i = 0; i < orderedOptions.length; i++) {
          const votes = orderedOptions[i].votes ?? 0;
          unaveragedSum += votes * i;
          totalVotes += votes;
        }
        break;
      }
      default:
        // No average score for other question types
        return undefined;
    }
  }

  const avgScore =
    totalVotes > 0 ? (unaveragedSum / totalVotes).toFixed(1) : "0";
  return avgScore;
}

export function pluralizePeople(numberOfPeople: number): string | undefined {
  if (typeof numberOfPeople !== "number") {
    return undefined;
  }
  return numberOfPeople === 1 ? "1 person" : `${numberOfPeople} people`;
}

export function calculateNPS(
  pollType: PollType,
  options: PostOptions[],
): string | undefined {
  if (pollType !== PollType.NPS) {
    return undefined;
  }

  let detractors = 0;
  for (let i = 0; i <= 6; i++) {
    detractors += options[i].votes ?? 0;
  }
  // passive are 7 - 8
  let passive = 0;
  for (let i = 7; i <= 8; i++) {
    passive += options[i].votes ?? 0;
  }
  // promoters are 9 - 10
  let promoters = 0;
  for (let i = 9; i <= 10; i++) {
    promoters += options[i].votes ?? 0;
  }
  const sum = detractors + passive + promoters;
  if (sum === 0) {
    // nobody has voted
    return "0";
  }
  const detractorsPercentage = detractors / sum;
  const promotersPercentage = promoters / sum;
  const npsScore = (promotersPercentage - detractorsPercentage) * 100;
  return npsScore.toFixed(0);
}

export function resultsAvailable(
  results: ResultsDeliveryType,
  closeDate: Date,
  postResultsOnClose: boolean,
): boolean {
  return (
    results === ResultsDeliveryType.REAL_TIME ||
    (postResultsOnClose && closeDate < new Date())
  );
}

/**
 * Sanitizes a limit to be passed to `.limit(foo)` or `{limit: foo}` for Mongo queries.
 * Returns `0` if the limit is `Infinity`, `null` or `undefined`, as `0`
 * is interpreted by MongoDB as an infinite limit.
 * @param limit
 */
export function sanitizeMongoQueryLimit(limit: number): number {
  if (limit === Infinity || _.isNil(limit)) {
    return 0;
  } else {
    return limit;
  }
}

interface ActionIdPayload {
  messageName: string;
  objectId: string;
  componentName: string;
  componentArgs: string;
}
/**
 * Splits the slack action_id into various parts. This requires that action_ids be formatted as:
 * `MessageName(objectId).ComponentName(componentArgs)
 * note that (componentArgs) is optional and only needs to be used when you have a bunch of instances
 * of the same element on the screen (e.g. a bunch of Vote buttons).
 * @param actionId slack action_id formatted as described above
 */
export function parseActionId(actionId: string): ActionIdPayload {
  const parts = actionId.split(".");
  const message = parts[0];
  const component = parts[1];

  const messageParts = message.match(/(.+?(?=\())\(([^)]+)\)/);
  const componentParts =
    component.indexOf("(") >= 0
      ? component.match(/(.+?(?=\())\(([^)]+)\)/)
      : [null, component, null];
  if (parts.length !== 2) {
    throw new Error(`Unable to parse actionId: ${actionId}`);
  }
  return {
    messageName: messageParts[1],
    objectId: messageParts[2],
    componentName: componentParts[1],
    componentArgs: componentParts[2],
  };
}

export function formatDate(date: Date, timezone: string): string {
  return moment(date).tz(timezone).format("MMM Do");
}

export function prefixDuplicateTitlesWithCreatedAt(
  documents: { _id?: string; createdAt: Date; title: string }[],
  timezone: string,
): Map<string, string> {
  const titleCounts = _.countBy(documents, "title");
  const result = new Map<string, string>();
  for (const document of documents) {
    const title =
      titleCounts[document.title] === 1
        ? document.title
        : formatDate(document.createdAt, timezone) + ": " + document.title;
    result.set(document._id, title);
  }
  return result;
}

/**
 * Invokes `logAction` only when one of the ids from `target` match the corresponding environment variables.
 *
 * @param target an object containing org id, channel id, or slack team id.
 * @param logAction the action to be performed when the target id matches
 * @param fallbackLogAction the fallback action to be performed when the target id doesn't match, if specified
 */
export function targetedLogging(
  target: { orgId?: string; channelId?: string; slackTeamId?: string },
  logAction: () => void,
  fallbackLogAction?: () => void,
): void {
  const { orgId, channelId, slackTeamId } = target;

  if (
    ORG_ID_WITH_BLOCK_LEVEL_LOGGING &&
    ORG_ID_WITH_BLOCK_LEVEL_LOGGING === orgId
  ) {
    logAction();
  } else if (
    CHANNEL_ID_WITH_BLOCK_LEVEL_LOGGING &&
    CHANNEL_ID_WITH_BLOCK_LEVEL_LOGGING === channelId
  ) {
    logAction();
  } else if (
    SLACK_TEAM_ID_WITH_BLOCK_LEVEL_LOGGING &&
    SLACK_TEAM_ID_WITH_BLOCK_LEVEL_LOGGING === slackTeamId
  ) {
    logAction();
  } else {
    fallbackLogAction && fallbackLogAction();
  }
}

function getTeamDisplayTextForSlack(team: Team) {
  const prefix = team.slackId[0];
  if (prefix === "D") {
    return `<@${team.lead[0]}>`;
  } else if (team.isPrivate) {
    // slack doesn't format private channels, so we give the title from the DB
    return team.title;
  } else {
    // Public channel, so we can use slack's interpolation
    return `<#${team.slackId}>`;
  }
}

export function formatPollAudienceTeams(teams: Team[]): string {
  if (teams.length > 0) {
    return teams.map(getTeamDisplayTextForSlack).join(", ");
  } else {
    return "No audience selected";
  }
}

export function allowSubmittingToChannel(pollGroupTeams: Team[]): boolean {
  // If nothing is selected, don't want to show submit via DM
  return (
    pollGroupTeams.length === 0 ||
    // otherwise, submitting to channel is possible only if it's exactly 1, not DM, not read-only
    // OR if it's a DM channel the bot isn't a part of (for limited polls)
    (pollGroupTeams.length === 1 &&
      !pollGroupTeams[0].isReadOnly &&
      (pollGroupTeams[0].slackId.startsWith("C") ||
        pollGroupTeams[0].slackId.startsWith("G") ||
        (pollGroupTeams[0].slackId.startsWith("D") &&
          !pollGroupTeams[0].isMember)))
  );
}

/**
 * Check if `date` falls into 10am-4pm on Mon-Fri based on the timezone of the `user`.
 */
export function isWithinWorkingHours(date: Date, user: User): boolean {
  const timezone = user.profile.tz || DEFAULT_TIME_ZONE;
  const hour = moment(date).tz(timezone).hour();
  const day = moment(date).tz(timezone).day();
  return day >= 1 && day <= 5 && hour >= 10 && hour <= 16;
}

/**
 * Safely gets an error message for an object caught by try/catch
 * @param error object caught by try/catch
 * @returns string to log. Includes name, message, and stack if available
 */
export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return JSON.stringify({
      name: error.name,
      message: error.message,
      stack: error.stack,
    });
  }

  try {
    return JSON.stringify(error);
  } catch {
    // If there are circular references just construct a string
    return String(error);
  }
}

// Truncates a string to a maximum of `numChars`,
// replacing any additional characters with an ellipsis.
export function ellipsis(str: string, numChars: number): string {
  // Length-checking is hard with emojis, we first need to transform these emoji codes
  // into actual emojis, then split the string into graphenes so some emojis don't get
  // counted as multiple characters
  const emojiConvertor = new EmojiConvertor();
  const withEmojis = emojiConvertor.replace_colons(str);
  const omission = "…";
  const splitter = new GraphemeSplitter();
  let splitGraphenes = splitter.splitGraphemes(withEmojis);
  if (splitGraphenes.length < numChars) {
    return str;
  } else {
    splitGraphenes = splitGraphenes.slice(0, numChars - omission.length);
    const truncatedString = splitGraphenes.join("") + omission;
    return emojiConvertor.replace_emoticons_with_colons(truncatedString);
  }
}

/**
 * Takes as input the 44px icon url that we store in our db and returns the 132px icon url
 * We only store the 44px icon in the organizations collection. Slack on its end also stores the 132px icon
 * @param originalIconUrl The 44px icon url from the organizations collection
 */
export function get132PxOrgIconUrl(originalIconUrl: string): string {
  // We store the 44px org icon in our db. The url for this ends in "_44". Slack also stores the 132 px icon on its end.
  // Replacing "_44" with "_132" let's us fetch the higher res org icon.
  // For the slack default avatar icons, the image source ends in -44 instead of _44

  return originalIconUrl.replace(
    new RegExp("(.+)(_44|-44)(.+)$"),
    (_match, p1, p2, p3, _offset) => {
      let replacement: string;
      if (p2 === "-44") {
        replacement = "-132";
      } else if (p2 === "_44") {
        replacement = "_132";
      } else {
        // some cases that we don't know about, return the original image src
        replacement = p2;
      }

      return `${p1}${replacement}${p3}`;
    },
  );
}

export function isGuestUserId(userId: string): userId is GuestUserId {
  return new RegExp(`gst(-[a-z0-9]{1,4})?:[a-z0-9-]`).test(userId);
}

export function isPlatformPollyUserId(userId: string): userId is PollyUserId {
  return new RegExp(`usr(-[a-z0-9]{1,4})?:[a-z0-9-]`).test(userId);
}

export function isPlatformPollyId(pollyId: string): pollyId is PollyId {
  return new RegExp(`ply(-[a-z0-9]{1,4})?:[a-z0-9-]`).test(pollyId);
}

export function getDateToUnblockResultsTo() {
  const dateInHistory = new Date();
  dateInHistory.setMonth(dateInHistory.getMonth() - MONTHS_UNBLOCK_RESULTS_FOR);

  return dateInHistory;
}
