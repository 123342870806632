import {
  AnonymityLevel,
  ResultsDeliveryType,
} from "@pollyslack/types/src/common";
import { NudgeMessageType, PollType } from "../../../../types";
import { IPollySchema } from "@pollyai/api-client-server";

/**
 * Replaces breaking spaces in a jsx string to non breaking spaces
 */
export function sanitizeJSXString(view: string): string {
  return view.replace(/\u00A0/g, " ");
}

/**
 * Returns the generated message string for a provided nudge message id.
 * @param messageName the string name of the nudge message being built
 */
export function getNudgeMessageString(messageName: NudgeMessageType): string {
  switch (messageName) {
    case NudgeMessageType.THREE_DAYS_AFTER_TRAIL:
      return "How's your trial going?";
    case NudgeMessageType.SEVEN_DAYS_AFTER_TRAIL:
      return "Your Polly trial is ending!";
    case NudgeMessageType.SEVEN_DAYS_AFTER_TRAIL_ENDS:
      return "It's been a week since your trial ended!";
    default:
      return "";
  }
}

/**
 * Returns boolean indicating whether to show the names of votes or not.
 * @param {AnonymityLevel} anonymityLevel to check if it's Public or Anonymized Results
 * @param {boolean} isAuthor whether the user viewing the results is author or not
 */

export function canShowVoterNames(
  anonymityLevel: AnonymityLevel,
  isAuthor: boolean,
  isSharing: boolean = false,
  isAuthorDM: boolean = false,
): boolean {
  if (
    isAuthorDM &&
    anonymityLevel !== AnonymityLevel.ANONYMOUS &&
    anonymityLevel !== AnonymityLevel.CONFIDENTIAL
  ) {
    return true;
  }

  if (isSharing && anonymityLevel !== AnonymityLevel.PUBLIC) {
    return false;
  }

  return (
    anonymityLevel === AnonymityLevel.PUBLIC ||
    (anonymityLevel === AnonymityLevel.ANONYMIZE_PARTICIPANT_RESULTS &&
      isAuthor)
  );
}

/**
 * Returns boolean indicating whether to show the usernames or not.
 * @param {AnonymityLevel} anonymityLevel to check if it's Anonymous, Confidential or Anonymized Results
 * @param {boolean} isAuthor whether the user viewing the results is author or not
 */

export function doNotShowUsername(
  anonymityLevel: AnonymityLevel,
  isAuthor: boolean,
  isSharing: boolean = false,
  isAuthorDM: boolean = false,
  isSharedWithUser: boolean = false,
): boolean {
  if (
    (isAuthorDM || isSharedWithUser) &&
    (anonymityLevel === AnonymityLevel.PUBLIC ||
      anonymityLevel === AnonymityLevel.ANONYMIZE_PARTICIPANT_RESULTS)
  ) {
    return false;
  }

  if (isSharing && anonymityLevel !== AnonymityLevel.PUBLIC) {
    return true;
  }

  return (
    anonymityLevel === AnonymityLevel.ANONYMOUS ||
    anonymityLevel === AnonymityLevel.CONFIDENTIAL ||
    (anonymityLevel === AnonymityLevel.ANONYMIZE_PARTICIPANT_RESULTS &&
      !isAuthor)
  );
}

export const isLimitedForWeb = (
  pollType: PollType,
  results: ResultsDeliveryType,
) => {
  return (
    (pollType !== "1to5" &&
      pollType !== "1to10" &&
      pollType !== "fivePoint" &&
      pollType !== "verbalBinary" &&
      pollType !== "nps" &&
      pollType !== "openEnded") ||
    results === ResultsDeliveryType.THREADED
  );
};

export const isOpenForumPolly = (polly: IPollySchema) => {
  return (
    polly.type === "qna" &&
    polly.targets.some(
      (t) =>
        t.__typename === "PollySlackChannelTargetSchema" &&
        t.type === "slack-channel" &&
        t.role === "moderation",
    )
  );
};
