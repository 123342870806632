import React from "react";

import { User } from "@pollyslack/hub/src/types";

import { LicenseDetails } from "../components/LicenseDetails";
import { licensesAssignedAtEntry } from "@pollyslack/hub/src/features/subscription/schema";

type LicenseAssignmentTabProps = {
  subscriptionId: string;
  authors: User[];
  reload: () => Promise<void>;
  licensesAssignedAt: licensesAssignedAtEntry;
};

export const LicenseAssignmentTab: React.FunctionComponent<
  LicenseAssignmentTabProps
> = (props: LicenseAssignmentTabProps) => {
  return (
    <div className="row">
      <LicenseDetails
        subscriptionId={props.subscriptionId}
        authors={props.authors ?? []}
        licensesAssignedAt={props.licensesAssignedAt}
        reload={props.reload}
      />
    </div>
  );
};
